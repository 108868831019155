import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Headline from "../components/headline/headline.component"
import styles from "./404.module.scss"
import SVG from "../../static/img/notfound.inline.svg"
import { Link } from "gatsby"
export default () => {
  return (
    <Layout>
      <SEO
        title="Page Not Found"
        description="There are no pages matched with the URL"
      />
      <Headline
        mainText="404 Error! Page Not Found"
        subText="Oops! Sorry I don't have anything matched your request :("
      />
      <div className="container">
        <div className={styles["image-container"]}>
          <Link to="/" className={styles["button"]}>
            Go To Home
          </Link>
          <SVG className={styles["svg"]} />
        </div>
      </div>
    </Layout>
  )
}
