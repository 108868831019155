import React from "react"
import styles from "./headline.module.scss"
const Headline = ({ mainText, subText }) => {
  return (
    <div className={styles.headline}>
      <div className={styles.headings}>
        <h1 className={styles["lg-heading"]}>{mainText}</h1>
        <div
          dangerouslySetInnerHTML={{ __html: subText }}
          className={styles["sm-heading"]}
        />
      </div>
    </div>
  )
}

export default Headline
